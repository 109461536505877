@import url(https://fonts.googleapis.com/css?family=Proxima+Nova&display=swap);
body, p, span {
  font-family: 'Proxima Nova', sans-serif !important;
}

.loaderWrapper {
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  align-items: center;
}
